import { useState, useEffect } from "react";
import axios from "axios";
import Select from 'react-select'
import { useNavigate } from "react-router-dom";

import year from "../../images/yeear.svg";
import engineSize from "../../images/engine.svg";
import calendar from "../../images/calendar.svg";
import { baseURL1 } from "../../utils/baseUrl";
import ClipLoader from "react-spinners/ClipLoader";
import apiClient from "../../utils/apiClient";
import { useSelector } from "react-redux";
import strings from "../../utils/locals/languages";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { toast } from "react-toastify";
import Slider from "@mui/material/Slider";
import MapComponent from "./MapView";
import locationIcon from "../../images/location.svg";
import arowDown from "../../images/arrowDown.svg";
import listIcon from "../../images/listIcon.svg";
import redLocationIcon from "../../images/redLocationIcon.svg";
import Cookies from "js-cookie";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import './index.css'
import Footer from '../../components/Footer'
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { handleCarClick } from "../../services/handlers";

const customSelect = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 50,
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    outline: "none",
    border: 'none'

  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#ddd' : null,
    color: state.isFocused ? '#333' : null,
  }),
}

const Body = ({ id }) => {
  const [showFilters, setShowFilters] = useState(false)
  const [viewType, setViewType] = useState("grid");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [showMap, setShowMap] = useState(false);
  const navigate = useNavigate();
  const language = useSelector((state) => state.language);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [finalSelectedCountries, setFinalSelectedCountries] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [city, setCity] = useState("");
  const [vehicleData, setVehicleData] = useState([]);
  const [reLoad, setReLoad] = useState(0);
  const [value1, setValue1] = useState([0, 100000000]);
  const [value2, setValue2] = useState([0, 9999999]);
  const [priceRange, setPriceRange] = useState();
  const [kmRange, setKmRange] = useState();
  const [filters, setFilters] = useState([]);
  const [fieldValues, setFieldValues] = useState({});
  const [userId, setUserId] = useState("");
  const [sortBy, setSortBy] = useState("");

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCountryTitle, setSelectedCountryTitle] = useState('')
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [topBrands, setTopBrands] = useState([])
  const [topBrandsLoading, setTopBrandsLoading] = useState(true);
  const [topModels, setTopModels] = useState([])
  const [topModelsLoading, setTopModelsLoading] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedModel, setSelectedModel] = useState(null)



  const [selectedFilterOptions, setSelectedFilterOptions] = useState({});

  const handleSelectChange = (fieldName, selectedValue) => {
    setSelectedFilterOptions(prevState => ({
      ...prevState,
      [fieldName]: selectedValue,
    }));
  };

  const clearFilters = async () => {
    window.location.reload()
    setSelectedCountry('')
    setSelectedCountryTitle('')
    setSelectedCities([])
    setSelectedDistricts([])
    setSelectedCategories([])
    setSelectedBrands([])
    setValue1([0, 100000000])
    setValue2([0, 9999999])
    setSelectedModels([])
    setFieldValues([])
    setFrom('')
    setTo('')
  }

  useEffect(() => {

    const cityIds = []

    selectedCities.forEach((item) => cityIds.push(item.id))

    axios
      .post(`${baseURL1}/v1/adds/districtDropdown`, {
        cityIds
      })
      .then((response) => {

        const original = response.data

        const modified = original.map((dist, index) => ({
          id: dist._id,
          label: dist.districtTitle,
          value: dist.districtTitle
        }));

        setDistricts(modified);
      })
      .catch((err) => { });
  }, [selectedCities]);

  useEffect(() => {

    const countryIds = []

    selectedCountries.forEach((item) => countryIds.push(item.id))

    axios
      .get(`${baseURL1}/v1/adds/citiesDropdown/${selectedCountry}`, {
        countryIds
      })
      .then((response) => {

        const original = response.data.cities;

        const modified = original.map((city, index) => ({
          id: city._id,
          label: city.cityTitle,
          value: city.cityTitle
        }));

        setCities(modified);
      })
      .catch((err) => { });
  }, [selectedCountry]);

  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/countiesDropdown`)
      .then((response) => {
        setCountries(response.data)
      })
      .catch((err) => { });
  }, [strings._language]);

  const geopoints = vehicleData.map((vehicle) => ({
    coordinates: {
      lat: vehicle.location.geoPoints.coordinates[1],
      lng: vehicle.location.geoPoints.coordinates[0],
    },
    price: `${vehicle.basicInformation.price.currency} ${vehicle.basicInformation.price.price}`,
    item: vehicle,
  }));

  const handlePriceRange = (event, newValue, activeThumb) => {
    const minDistance = 10;
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const handleKmRange = (event, newValue, activeThumb) => {
    const minDistance = 15;
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue2([Math.min(newValue[0], value2[1] - minDistance), value2[1]]);
    } else {
      setValue2([value2[0], Math.max(newValue[1], value2[0] + minDistance)]);
    }
  };

  const addToFavorites = (event, adId) => {
    event.stopPropagation();

    apiClient
      .get(`/v1/user/fav-unfav/${adId}`)
      .then((res) => {
        setReLoad(reLoad + 1);
      })
      .catch((err) => {
        toast.error(`${strings.failedToAdd}`);
      });
  };

  const getTopModels = (brandId) => {
    setSelectedBrand(brandId)
    setTopModelsLoading(true)
    axios
      .post(
        `${baseURL1}/v1/adds/models-dropdown-with-mutiple-brands?language=${strings._language}`, {
        brandIds: [`${brandId}`]
      }
      )
      .then((response) => {
        setTopModels(response.data)
        setTopModelsLoading(false)
      })
      .catch((err) => { });
  }


  const searchFilter = () => {

    const cities = []
    const district = []
    const category = []
    const brand = []
    const model = []
    const engineSize = []
    const wheelDrive = []
    const fuelType = []
    const color = []
    const warranty = []

    selectedCities.forEach((item) => (
      cities.push(item.label)
    ))

    selectedDistricts.forEach((item) => (
      district.push(item.label)
    ))

    selectedCategories.forEach((item) => (
      category.push(item.id)
    ))

    selectedBrands.forEach((item) => (
      brand.push(item.id)
    ))

    selectedModels.forEach((item) => (
      model.push(item.id)
    ))

    selectedFilterOptions?.Engine?.forEach((item) => {
      engineSize.push(item.label)
    })

    selectedFilterOptions?.Wheel?.forEach((item) => {
      wheelDrive.push(item.label)
    })

    selectedFilterOptions?.Fuel?.forEach((item) => {
      fuelType.push(item.label)
    })

    selectedFilterOptions?.Color?.forEach((item) => {
      color.push(item.label)
    })

    selectedFilterOptions?.Warranty?.forEach((item) => {
      warranty.push(item.label)
    })




    setIsLoading(true);
    apiClient
      .post(
        `/v1/adds/advance-filter-adds?section=638f1fd34cb7c29ec6ad4bd6&c&language=${strings._language}`, {
        country: selectedCountryTitle,
        cities,
        district,
        // minPrice: value1[0].toString(),
        // maxPrice: value1[1].toString(),
        startYear: from,
        endYear: to,
        kmMin: value2[0].toString(),
        kmMax: value2[1].toString(),
        category: selectedCategories.length === 0 ? [`${id}`] : category,
        brand: (selectedBrands.length === 0 && selectedBrand !== null) ? [`${selectedBrand}`] : brand,
        model: (selectedModels.length === 0 && selectedModel !== null) ? [`${selectedModel}`] : model,
        engineSize,
        wheelDrive,
        fuelType,
        color,
        warranty

      }
      )
      .then((response) => {
        setVehicleData(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => { });
  };


  useEffect(() => {
    searchFilter();
  }, [
    strings._language,
    selectedCountry,
    selectedCities,
    selectedDistricts,
    value1,
    value2,
    selectedCategories,
    selectedBrands,
    selectedModels,
    from,
    to,
    selectedBrand,
    selectedModel,
    selectedFilterOptions
  ]);


  useEffect(() => {
    apiClient
      .get(
        `${baseURL1}/v1/adds/section/feature?section=vehicle&language=${strings._language}`
      )
      .then((res) => {
        const myData = res.data.detail.filter(
          (item) =>
            item.fieldName === "Year" ||
            item.fieldName === "Fuel Type" ||
            item.fieldName === "Engine Size" ||
            item.fieldName === "Wheel Drive" ||
            item.fieldName === "Color" ||
            item.fieldName === "Warranty" ||
            item.fieldName === "год" ||
            item.fieldName === "Тип топлива" ||
            item.fieldName === "Размер двигателя" ||
            item.fieldName === "Полный привод" ||
            item.fieldName === "Цвет" ||
            item.fieldName === "Гарантия" ||
            item.fieldName === "Yil" ||
            item.fieldName === `Yoqilg'i turi` ||
            item.fieldName === "Dvigatel hajmi" ||
            item.fieldName === `G'ildirakli haydovchi` ||
            item.fieldName === "Rang" ||
            item.fieldName === "Kafolat"
        );

        setFilters(myData);
      })
      .catch((err) => { });;
  }, [strings._language]);

  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/sections?language=${strings._language}`)
      .then((response) => {
        const original = response.data[0].categories

        const modified = original.map((category, index) => ({
          id: category._id,
          label: category.title,
          value: category.title
        }));

        setCategories(modified);
      })
      .catch((err) => { });
  }, [strings._language]);

  useEffect(() => {
    setTopBrandsLoading(true)
    axios
      .post(
        `${baseURL1}/v1/adds/brands-dropdown-with-multiple-category?language=${strings._language}`, {
        categoryIds: [`${id}`]
      }
      )
      .then((response) => {
        setTopBrandsLoading(false)
        setTopBrands(response.data)
      })
      .catch((err) => { });
  }, [strings._language]);



  useEffect(() => {

    const categoryIds = []

    selectedCategories.forEach((item) => (
      categoryIds.push(item.id)
    ))

    axios
      .post(
        `${baseURL1}/v1/adds/brands-dropdown-with-multiple-category?language=${strings._language}`, {
        categoryIds
      }
      )
      .then((response) => {
        const original = response.data

        const modified = original.map((brand, index) => ({
          id: brand._id,
          label: brand.title,
          value: brand.title
        }));

        setBrands(modified);
      })
      .catch((err) => { });
  }, [selectedCategories]);




  useEffect(() => {
    const userDetails = Cookies.get("userDetails");

    if (userDetails) {
      const user = JSON?.parse(userDetails);
      setUserId(user._id);
    } else {
      setUserId("0");
    }
  }, []);

  useEffect(() => {
    // Map the selectedCountries array to extract only the 'value' attribute
    const valuesArray = selectedCountries.map(country => country.value);

    // Update the finalCountries state with the new array
    setFinalSelectedCountries(valuesArray);
  }, [selectedCountries]);

  const itemsToShow = 8

  const itemWidth = 300
  const isBrandsEnd = itemWidth * ((topBrands.length) - 12)
  const [brandsScrollPosition, setBrandsScrollPosition] = useState(0);

  const brandNextButtonClickHandler = () => {
    const nextPosition = Math.min(
      brandsScrollPosition + itemWidth,
      ((topBrands.length + 2) - itemsToShow) * itemWidth
    );

    if (nextPosition <= isBrandsEnd) {
      setBrandsScrollPosition(nextPosition);

    }
  };

  const brandPrevButtonClickHandler = () => {
    const prevPosition = Math.max(brandsScrollPosition - itemWidth, 0);
    setBrandsScrollPosition(prevPosition);
  };


  const [isModelsEnd, setIsModalEnd] = useState(0)
  const [modelsScrollPosition, setModelsScrollPosition] = useState(0);

  const modelNextButtonClickHandler = () => {
    const nextPosition = Math.min(
      modelsScrollPosition + itemWidth,
      ((topBrands.length + 2) - itemsToShow) * itemWidth
    );

    if (nextPosition <= isModelsEnd) {
      setModelsScrollPosition(nextPosition);
    }

  };

  const modelPrevButtonClickHandler = () => {
    const prevPosition = Math.max(modelsScrollPosition - itemWidth, 0);
    setModelsScrollPosition(prevPosition);
  };

  useEffect(() => {

    const brandIds = []

    selectedBrands.forEach((item) => (
      brandIds.push(item.id)
    ))
    axios
      .post(
        `${baseURL1}/v1/adds/models-dropdown-with-mutiple-brands?language=${strings._language}`, {
        brandIds
      }
      )
      .then((response) => {
        const original = response.data

        const modified = original.map((model, index) => ({
          id: model._id,
          label: model.title,
          value: model.title
        }));

        setModels(modified);
      })
      .catch((err) => { });
  }, [selectedBrands]);


  useEffect(() => {
    setIsModalEnd(itemWidth * ((topModels.length - 3)))
  }, [topModels])


  return (
    <div>
      <div className="container relative mx-auto">

        <div className="w-full md:hidden flex justify-end">
          <div className="bg-[#F37521] cursor-pointer text-white p-2 rounded-md "
            onClick={() => {
              if (showFilters) {
                setShowFilters(false)
              } else {
                setShowFilters(true)
              }
            }}
          >
            Show Filters
          </div>
        </div>
        <div className="flex justify-between ">
          <h4 className="uppercase font-semibold text-[20px] mb-4">
            {strings.brand}S
          </h4>
          <div
            className="absolute z-50 top-32 -left-12 md:block hidden"
          >
            <ArrowBackIosNewIcon
              className={`cursor-pointer   rounded-full p-1 bg-white text-[#F37521]`}
              onClick={() => brandPrevButtonClickHandler()}
              style={{
                height: 48,
                width: 48
              }}
            />
          </div>
          <div
            className="absolute z-50 top-32 -right-14 md:block hidden"
          >
            <ArrowForwardIosIcon
              className={`cursor-pointer  rounded-full p-1 bg-white text-[#F37521] `}
              onClick={() => brandNextButtonClickHandler()}
              style={{
                height: 48,
                width: 48
              }}
            />
          </div>
        </div>
        {topBrandsLoading ? (
          <div
            className="flex gap-[52px] flex-nowrap overflow-x-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-100"
            style={{ width: "100%" }}
          >
            {[1, 2, 3, 4].map((_, index) => (
              <div
                className="rounded-2xl shadow-md bg-white w-[200px]  flex-none mb-3 cursor-pointer"
                key={index}
              >
                <Skeleton
                  style={{
                    height: "200px",
                    width: "100%",
                    borderTopLeftRadius: "16px",
                    borderTopRightRadius: "16px",
                  }}
                  className="max-h--sm h-60  shadow-sm"
                />
                <div className="text-center text-sm font-medium py-1">
                  <Skeleton height={10} width={50} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            className='gap-4 flex p-2 bg-transparent overflow-y-auto '
          >
            {topBrands.sort((a, b) => a.title.localeCompare(b.title)).map((item, index) => (
              <div
                className={`flex w-[400px] flex-col shadow-md rounded-xl  cursor-pointer ${selectedBrand === item._id ? 'border-2 border-[#F37521]' : ''}`}
                style={{
                  transition: 'transform 0.3s',
                  transform: `translateX(-${brandsScrollPosition}px)`,
                }}
                key={index}
                onClick={() => getTopModels(item._id)}
              >

                <div className="w-[200px]">
                  <img
                    src={item.imageUrl}
                    alt="Category"
                    className="h-[150px] w-[100%] rounded-2xl shadow-sm"
                  />
                </div>
                <h6 className="text-center text-sm font-extrabold py-1">
                  {item.title} <span className="text-orange-400"> ({item.totalAdds})</span>
                </h6>
              </div>
            ))}
          </div>
        )}

        {
          topModels.length !== 0 && (
            <>
              <div className="relative flex mt-4 justify-between ">
                <h4 className="uppercase font-semibold text-[20px] mb-4">
                  {strings.model}S
                </h4>
                <div
                  className="absolute z-50 top-32 -left-12 md:block hidden"
                >
                  <ArrowBackIosNewIcon
                    className={`cursor-pointer rounded-full p-1 bg-white text-[#F37521] `}
                    onClick={() => modelPrevButtonClickHandler()}
                    style={{
                      height: 48,
                      width: 48
                    }}
                  />
                </div>
                <div
                  className="absolute z-50 top-32 -right-20 md:block hidden"
                >
                  <ArrowForwardIosIcon
                    className={`cursor-pointer  rounded-full p-1 bg-white text-[#F37521] `}
                    onClick={() => modelNextButtonClickHandler()}
                    style={{
                      height: 48,
                      width: 48
                    }}
                  />
                </div>

              </div>
              {topModelsLoading ? (
                <div
                  className="flex gap-[52px] flex-nowrap overflow-x-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-100"
                  style={{ width: "100%" }}
                >
                  {[1, 2, 3, 4].map((_, index) => (
                    <div
                      className="rounded-2xl shadow-md bg-white w-[200px]  flex-none mb-3 cursor-pointer"
                      key={index}
                    >
                      <Skeleton
                        style={{
                          height: "200px",
                          width: "100%",
                          borderTopLeftRadius: "16px",
                          borderTopRightRadius: "16px",
                        }}
                        className="max-h--sm h-60  shadow-sm"
                      />
                      <div className="text-center text-sm font-medium py-1">
                        <Skeleton height={10} width={50} />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  className='gap-4 flex p-2 bg-transparent overflow-y-auto '
                >
                  {topModels.sort((a, b) => a.title.localeCompare(b.title)).map((item, index) => (
                    <div
                      className={`flex  flex-col shadow-md rounded-xl  cursor-pointer ${selectedModel === item._id ? 'border-2 border-[#F37521]' : ''}`}
                      style={{
                        transition: 'transform 0.3s',
                        transform: `translateX(-${modelsScrollPosition}px)`,
                      }}
                      key={index}
                      onClick={() => setSelectedModel(item._id)}
                    >

                      <div className="w-[200px]">
                        <img
                          src={item.imageUrl}
                          alt="Category"
                          className="h-[150px] w-[100%] rounded-2xl shadow-sm"
                        />
                      </div>
                      <h6 className="text-center text-sm font-extrabold py-1">
                        {item.title}  <span className="text-orange-400"> ({item.totalAdds})</span>
                      </h6>
                    </div>
                  ))}
                </div>
              )}
            </>
          )
        }

        {/* Basic filters  */}
        <div className={`md:flex ${!showFilters && 'hidden'} justify-between items-center flex-col sm:flex-col md:flex-row pb-4`}>
          <h3 className="mt-3 text-gray-600">{strings.filters}</h3>
          <div className=" flex gap-3 justify-end flex-col items-center md:flex-row mt-3">
            <div className="flex md:w-max w-[200px] justify-center rounded-full  gap-3 justify-items-center cursor-pointer"
              style={{
                border: showMap ? "1px solid #F77A23" : "1px solid black",
                padding: "14px",
              }}
            >
              <ImportExportIcon style={{ height: "18px", width: "18px" }} />
              <select
                className=" text-sm font-semibold outline-none bg-transparent "
                onChange={(e) => {
                  setSortBy(e.target.value);
                }}
                value={sortBy}
              >
                <option value="">{strings.sortBy}</option>
                <option value="ph">{strings.priceHighest}</option>
                <option value="pl">{strings.priceLowest}</option>
                <option value="new">{strings.newest}</option>
                <option value="old">{strings.oldFirst}</option>
                <option value="atoz">{strings.alpAZ}</option>
                <option value="ztoa">{strings.alpZA}</option>
              </select>
            </div>
            <div
              className="flex md:w-max w-[200px] justify-center rounded-full  gap-3 justify-items-center cursor-pointer "
              style={{
                border: showMap ? "1px solid #F77A23" : "1px solid black",
                padding: "10px",
              }}
              onClick={() => {
                setShowMap(true);
              }}
            >

              <img
                className="w-5 h-6 mt-1"
                src={showMap ? redLocationIcon : locationIcon}
                alt=""
              />
              <button
                style={{
                  color: showMap ? "#F77A23" : "#000",
                }}
              >
                {strings.mapView}
              </button>
            </div>
            <div
              className="flex md:w-max w-[200px] justify-between h-12 p-1 px-2  rounded-full gap-2 justify-items-center cursor-pointer"
              style={{ border: "1px solid black" }}
              onClick={() => {
                setShowMap(false);
              }}
            >
              <img
                className=" h-4  ms-1"
                src={listIcon}
                alt=""
                style={{ marginTop: "11px" }}
              />
              <button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {strings.viewType}
              </button>
              <img className="w-3" src={arowDown} alt="" />

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setViewType("grid");
                    handleClose();
                  }}
                >
                  Grid View
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setViewType("list");
                    handleClose();
                  }}
                >
                  List View
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="col-md row">

          {/*Filters */}
          <div className={`col-lg-3 md:block md:mt-0 mt-4 ${!showFilters && 'hidden'} col-md-4 col-sm-6`}>
            <div className="bg-white rounded-xl shadow-md  p-3 ">
              <h6 className="text-gray-500 text-[20px] font-semibold">{strings.locationFilter}</h6>
              <h6 style={{ marginTop: "10px" }} className="text-gray-500">
                {strings.country}
              </h6>
              <select
                type="text"
                className="text-gray-500 bg-white text-sm"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: 20,
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  outline: "none",
                  marginTop: 12,
                }}
                onChange={(e) => {
                  const selectedId = e.target.value;
                  const selectedOption = countries.find((option) => option._id === selectedId);

                  if (selectedOption) {
                    setSelectedCountry(selectedId);
                    setSelectedCountryTitle(selectedOption.countryTitle);
                  }
                }}
                value={selectedCountry} // Assuming you want to control the selected value
              >
                <option>{strings.select}</option>
                {countries.map((option, index) => (
                  <option key={index} value={option._id}>
                    {option.countryTitle.charAt(0).toUpperCase() + option.countryTitle.slice(1)}
                  </option>
                ))}

              </select>

              <h6 style={{ marginTop: "10px" }} className="text-gray-500">
                {strings.city}
              </h6>

              <Select
                options={cities}
                value={selectedCities}
                onChange={setSelectedCities}
                labelledBy="Select"
                styles={customSelect}
                isMulti

              />

              <h6 style={{ marginTop: "10px" }} className="text-gray-500">
                {strings.distt}
              </h6>

              <Select
                id="brand"
                options={districts}
                value={selectedDistricts}
                onChange={setSelectedDistricts}
                labelledBy="Select"
                styles={customSelect}
                isMulti

              />

              <h6 style={{ marginTop: "30px" }} className="text-gray-500">
                {strings.priceRange}
              </h6>

              <div className="flex gap-3 justify-around">
                <input
                  type="text"
                  className=" text-gray-500 bg-white text-sm"
                  style={{
                    width: "45%",
                    padding: "10px",
                    borderRadius: 20,
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                    // fontSize: 18,
                    outline: "none",
                    marginTop: 12,
                  }}
                  onChange={(e) => setValue1([e.target.value, value1[1]])}
                  value={value1[0]}
                />

                <input
                  type="text"
                  className=" text-gray-500 bg-white text-sm"
                  style={{
                    width: "45%",
                    padding: "10px",
                    borderRadius: 20,
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                    outline: "none",
                    marginTop: 12,
                  }}
                  onChange={(e) => setValue1([value1[0], e.target.value])}
                  value={value1[1]}
                />
              </div>

              <div className="mt-4 mx-2">
                <Slider
                  getAriaLabel={() => "Minimum distance"}
                  value={value1}
                  onChange={handlePriceRange}
                  getAriaValueText={priceRange}
                  style={{
                    color: "orange",
                  }}
                  min={0}
                  max={1000000}
                />
              </div>
              <div className="flex justify-between">
                <div>{value1[0]}</div>
                <div>{value1[1]}</div>
              </div>

              <h6 style={{ marginTop: "30px" }} className="text-gray-500">
                KM
              </h6>

              <div className="flex gap-2 justify-around">
                <input
                  type="text"
                  className=" text-gray-500 bg-white text-sm"
                  style={{
                    width: "45%",
                    padding: "10px",
                    borderRadius: 20,
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                    // fontSize: 18,
                    outline: "none",
                    marginTop: 12,
                  }}
                  onChange={(e) => setValue2([e.target.value, value2[1]])}
                  value={value2[0]}
                />

                <input
                  type="text"
                  className=" text-gray-500 text-sm bg-white"
                  style={{
                    width: "45%",
                    padding: "10px",
                    borderRadius: 20,
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                    // fontSize: 18,
                    outline: "none",
                    marginTop: 12,
                  }}
                  onChange={(e) => setValue2([value2[0], e.target.value])}
                  value={value2[1]}
                />
              </div>

              <div className="mt-4 mx-2">
                <Slider
                  getAriaLabel={() => "Minimum distance"}
                  value={value2}
                  onChange={handleKmRange}
                  getAriaValueText={kmRange}
                  style={{
                    color: "orange",
                  }}
                  min={0}
                  max={9999999}
                />
              </div>
              <div className="flex justify-between">
                <div>{value2[0]}</div>
                <div>{value2[1]}</div>
              </div>

              <div className="flex justify-around mt-8">
                <div>From</div>
                <div>To</div>
              </div>

              <div className="flex justify-around mt-2">
                <div className="w-[45%]">
                  <input type="text" className="rounded-full shadow-md bg-white  w-[100%] outline-none p-2"
                    onChange={(e) => setFrom(e.target.value)}
                    value={from}
                  />
                </div>
                <div className="w-[45%]">
                  <input type="text" className="rounded-full shadow-md bg-white w-[100%] outline-none p-2"
                    onChange={(e) => setTo(e.target.value)}
                    value={to}
                  />
                </div>
              </div>

              <h6 style={{ marginTop: "30px" }} className="text-gray-500">
                {strings.category}
              </h6>

              <Select
                options={categories}
                value={selectedCategories}
                onChange={setSelectedCategories}
                labelledBy="Select"
                styles={customSelect}
                isMulti
              />


              <h6 style={{ marginTop: "30px" }} className="text-gray-500">
                {strings.subCate}
              </h6>


              <Select
                options={brands}
                value={selectedBrands}
                onChange={setSelectedBrands}
                styles={customSelect}
                isMulti

              />


              <h6 style={{ marginTop: "30px" }} className="text-gray-500">
                {strings.model}
              </h6>

              <Select
                options={models}
                value={selectedModels}
                onChange={setSelectedModels}
                styles={customSelect}
                isMulti

              />


              {filters.filter((dropdown) => dropdown.fieldName !== 'Year').map((item) => {
                const transformedOptions = item?.dropDown?.map((value) => ({
                  label: value,
                  value: value,
                }));
                return (
                  <>
                    <h6 style={{ marginTop: "30px" }} className="text-gray-500">
                      {item.fieldName}
                    </h6>
                    <Select
                      options={transformedOptions}
                      selected={selectedFilterOptions[item.fieldName.split(' ')[0]]}
                      onChange={(selectedValue) => handleSelectChange(item.fieldName.split(' ')[0], selectedValue)}
                      labelledBy="Select"
                      styles={customSelect}
                      isClearable={false}
                      isMulti
                    />

                  </>
                )
              })}
            </div>

            <div className="bg-[#F17522] mt-3 p-2 text-center rounded-md text-white cursor-pointer"
              onClick={() => clearFilters()}
            >
              Clear Filters
            </div>
          </div>
          <div className="col-lg-9 md:mt-0 mt-4 col-md-8 col-sm-6">
            {!showMap ? (
              <>
                {isLoading ? (
                  <div className="container flex justify-center mt-10">
                    <div>
                      <ClipLoader
                        loading={isLoading}
                        size={32}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    {vehicleData.length === 0 ? (
                      <div className="flex justify-center">
                        <h3>{strings.norec}</h3>
                      </div>
                    ) : (
                      <div
                        className={`${viewType === "grid"
                          ? "grid md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4"
                          : "grid md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-4"
                          }`}
                      >
                        {
                          vehicleData.sort((a, b) => {
                            if (sortBy === "ph") {
                              const priceA = Number(a?.basicInformation?.price?.price?.replace(/,/g, ''));
                              const priceB = Number(b?.basicInformation?.price?.price?.replace(/,/g, ''));

                              return priceB - priceA;
                            } else if (sortBy === "pl") {
                              const priceA = Number(a?.basicInformation?.price?.price?.replace(/,/g, ''));
                              const priceB = Number(b?.basicInformation?.price?.price?.replace(/,/g, ''));

                              return priceA - priceB;
                            } else if (sortBy === "atoz") {
                              return a?.basicInformation?.title?.localeCompare(
                                b?.basicInformation?.title
                              );
                            } else if (sortBy === "ztoa") {
                              return b?.basicInformation?.title?.localeCompare(
                                a?.basicInformation?.title
                              );
                            } else if (sortBy === "new") {
                              return (
                                new Date(b.createdAt) - new Date(a.createdAt)
                              );
                            } else if (sortBy === "old") {
                              return (
                                new Date(a.createdAt) - new Date(b.createdAt)
                              );
                            } else {
                              return 0; // No sorting
                            }
                          })
                            .map((item, index) => {
                              return (
                                <div
                                  className={`ca  flex ${viewType === "list"
                                    ? "flex-row w-full"
                                    : "flex-col"
                                    } shadow-xl rounded-xl border-none cursor-pointer`}
                                  style={
                                    {
                                      // ...
                                      // borderRadius: "20px",
                                    }
                                  }
                                  onClick={() => {
                                    handleCarClick(navigate, item._id, item.basicInformation.title, item.basicInformation.category.title, item.basicInformation.brand.title, item.basicInformation.model.title, item.location.city, item.location.district, item.basicInformation.KM)


                                  }}
                                >
                                  <div
                                    className={`inline-block ${viewType === "list"
                                      ? "w-1/2 h-[185px]"
                                      : "relative h-52"
                                      }`}
                                  >
                                    {viewType !== "list" && // Hide icons when viewType is "list"
                                      (!item.fav.includes(userId) ? (
                                        <FavoriteBorderOutlinedIcon
                                          className="items-content-end w-12 absolute"
                                          style={{
                                            right: "8px",
                                            color: "#F37521",
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            padding: 3,
                                            top: 8,
                                          }}
                                          onClick={(event) =>
                                            addToFavorites(event, item._id)
                                          }
                                        />
                                      ) : (
                                        <FavoriteOutlinedIcon
                                          className="items-content-end w-12 absolute"
                                          style={{
                                            right: "8px",
                                            color: "red",
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            padding: 3,
                                            top: 8,
                                          }}
                                          onClick={(event) =>
                                            addToFavorites(event, item._id)
                                          }
                                        />
                                      ))}
                                    <img
                                      src={item?.coverPhoto}
                                      alt="random"
                                      className={`${viewType === "list"
                                        ? "rounded-tl-xl rounded-bl-xl"
                                        : "rounded-tr-xl rounded-tl-xl"
                                        }`}
                                      style={{
                                        width: "100%", // Set width to 100%
                                        height: "100%", // Set height to 100%
                                        objectFit: "cover",
                                      }}
                                    />
                                  </div>

                                  <div
                                    className={`px-[10px] ${viewType === "list"
                                      ? "px-4 w-1/2 space-y-2 pt-1"
                                      : ""
                                      }`}
                                  >
                                    {" "}
                                    {/* Adjust font size in list view */}
                                    <div className="flex items-center mt-1 justify-between">
                                      <div>
                                        <h3 className="font-[500] text-sm whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
                                          {item?.basicInformation?.title.slice(0, 18)}
                                        </h3>
                                      </div>
                                      <div
                                        className={`${viewType === "list" ? "hidden" : ""
                                          }`}
                                        style={{ color: "#F37521" }}
                                      >
                                        {viewType === "grid" && ( // Only render when viewType is "list"
                                          <div className="flex">
                                            <h1 className={`font-bold text-sm`}>
                                              {item?.basicInformation?.price?.currency}&nbsp;
                                            </h1>
                                            <h1 className={`font-bold text-sm`}>
                                              {Number(item?.basicInformation?.price?.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                            </h1>
                                          </div>
                                        )}
                                      </div>
                                      {viewType === "list" && // Hide icons when viewType is "list"
                                        (!item.fav.includes(userId) ? (
                                          <FavoriteBorderOutlinedIcon
                                            className="items-content-end w-12 "
                                            style={{
                                              right: "8px",
                                              color: "#F37521",
                                              backgroundColor: "white",
                                              borderRadius: "50%",
                                              padding: 3,
                                              top: 8,
                                            }}
                                            onClick={(event) =>
                                              addToFavorites(event, item._id)
                                            }
                                          />
                                        ) : (
                                          <FavoriteOutlinedIcon
                                            className="items-content-end w-12 "
                                            style={{
                                              right: "8px",
                                              color: "red",
                                              backgroundColor: "white",
                                              borderRadius: "50%",
                                              padding: 3,
                                              top: 8,
                                            }}
                                            onClick={(event) =>
                                              addToFavorites(event, item._id)
                                            }
                                          />
                                        ))}
                                    </div>
                                    <div className={`flex  -mb-2 `}>
                                      {" "}
                                      {/* Adjust font size in list view */}
                                      <h1
                                        className={`text-[9px] font-[350] text-gray-400 ${viewType === "list" ? "text-[12px]" : ""
                                          }`}
                                      >
                                        {item?.basicInformation?.description.slice(
                                          0,
                                          15
                                        )}
                                      </h1>
                                    </div>
                                    <div
                                      className={`${viewType === "grid" ? "hidden" : ""
                                        }`}
                                      style={{ color: "#F37521" }}
                                    >
                                      {viewType === "list" && ( // Only render when viewType is "list"
                                        <div className="flex">
                                          <p className={` font-extrabold  text-lg`}>
                                            {item?.basicInformation?.price?.currency}&nbsp;
                                          </p>
                                          <p className={` font-bold text-lg`}>
                                            {Number(item?.basicInformation?.price?.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                    <div className={`flex text-[10px] text-gray-400 items-center -mb-1 ${viewType === "list" ? "text-[12px] justify-between pr-8" : "justify-between"}`}>

                                      <div className="flex gap-2 items-center">
                                        <img
                                          src={year}
                                          alt="year"
                                          className={`h-3  w-3 ${viewType === "list" ? "h-6 w-6" : ""
                                            }`}
                                        />
                                        <p className="text pt-3">
                                          {item?.basicInformation?.details[0]?.value}
                                        </p>
                                      </div>
                                      <div className="flex gap-2 items-center">
                                        <img
                                          src={engineSize}
                                          alt="year"
                                          className={`h-3  w-3 ${viewType === "list" ? "h-6 w-6" : ""
                                            }`}
                                        />
                                        <p className="text pt-3">
                                          {item?.basicInformation?.details
                                            .filter((item) =>
                                              [
                                                "Engine Size",
                                                "Размер двигателя",
                                                "Dvigatel hajmi",
                                              ].includes(item.fieldName)
                                            )
                                            .map((filteredItem, index) => (
                                              <span key={index}>
                                                {filteredItem.value}
                                              </span>
                                            ))}
                                        </p>
                                      </div>
                                      <div className="flex gap-2 items-center">
                                        <img
                                          src={calendar}
                                          alt="year"
                                          className={`h-3  w-3 opacity-60 ${viewType === "list" ? "h-6 w-6" : ""
                                            }`}
                                        />
                                        <p className="text pt-3">
                                          {item?.basicInformation?.KM}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <MapComponent geopoints={geopoints} />
            )}
          </div>
        </div>
        <Footer strings={strings} />
      </div>

    </div>
  );
};

export default Body;
